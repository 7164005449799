import React, { useState, useEffect }  from "react";
import { useLocation } from "react-router-dom";

const LiveChat = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (location.pathname === '/get-started') {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [location]);
  
  return (
    isVisible &&
    <div className="live-chat-container">
      <div className="chat-button-container">
        <div className="chat-button">
          <img className="dot" src={`https://101.it.com/images/Dot.webp`} alt="Ellipsis" loading='lazy'/>
          <img className="dot" src={`https://101.it.com/images/Dot.webp`} alt="Ellipsis" loading='lazy'/>
          <img className="dot" src={`https://101.it.com/images/Dot.webp`} alt="Ellipsis" loading='lazy'/>
        </div>
      </div>
    </div>
  );
};

export default LiveChat;