import React, { useState, useCallback } from 'react';import './GetStartedPage.css';
import { Button, Dialog, Grow } from '@mui/material';
import { useResponsiveQueries } from '../../utils/constants';

const GameLobbyUIDesign = React.memo((props) => {
  const [open, setOpen] = useState(false);
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const { src, alt, category, gameLobbyUIDesigns: selectedGameLobbyUIDesigns, onClick } = props;
  const isSelected = selectedGameLobbyUIDesigns[category]?.alt === alt;

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const showTemplateForm = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div className="game-lobby-ui-design">
      <div className="ui-design-label">{alt}</div>
      <div 
        style={isSelected ? 
          {maxWidth: 394, width: '100%', pointerEvents: 'none'} :
          {maxWidth: 394}
        } 
        className="image-container"
      >
        <div style={{margin: 0}} className='ui-design-image-container'>
          <img 
            className="ui-design-image" 
            src={`https://101.it.com/images/${src}.webp`} 
            alt={alt}
            loading='lazy'
            onClick={handleClickOpen} 
          />
          <div className="preview-label">Preview</div>
        </div>
        {isSelected && <img className='check-icon' style={{top: '-8%'}} src={`https://101.it.com/images/Check.webp`} alt='Check' loading='lazy'/>}
      </div>
      <Button 
        id="action-button"
        style={{maxWidth: 320, width: '95%', marginTop: '4%'}}
        disabled={isSelected}
        onClick={showTemplateForm} 
      >
        Select
      </Button>
      <Dialog 
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }} 
        TransitionComponent={Grow}
        transitionDuration={500}
        onClose={handleClose} 
        maxWidth="xl"
        open={open}
      >
        <img style={!MOBILE_WIDTH ? {height: '90vh'} : {}} src={`https://101.it.com/images/${src}.webp`}  alt={alt} loading='lazy'/>
      </Dialog>
    </div>
  );
});

export default GameLobbyUIDesign;