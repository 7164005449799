import React from 'react';
import './ResponsibleGamingPage.css';
import { useResponsiveQueries } from '../../utils/constants';

const reminderList = [
  {
    keyword: "Self-Exclusion",
    description: "Lorem ipsum dolor sit amet consectetur. Sed pretium ipsum ultricies faucibus sit fermentum ultrices vitae laoreet. Enim adipiscing phasellus vitae et. Augue quis suspendisse phasellus libero eros. Amet lobortis volutpat velit augue blandit habitant. In nulla vitae volutpat elementum. Tempor nisi platea pharetra ut at egestas risus. Consequat urna velit adipiscing quam sit id metus. Pretium eu sed varius sit porttitor diam tincidunt. Nibh fermentum malesuada accumsan vulputate faucibus urna adipiscing sed eget. Volutpat placerat quam amet congue commodo ut integer nulla. Interdum sit morbi porttitor sed massa sit maecenas a. Tempor nec lacus euismod ullamcorper auctor turpis eu. Orci mattis nibh eget eget arcu neque suspendisse eleifend auctor. Vitae placerat fusce urna mauris donec at sit diam."
  },
  {
    keyword: "Play Limits",
    description: "Lorem ipsum dolor sit amet consectetur. Sed pretium ipsum ultricies faucibus sit fermentum ultrices vitae laoreet. Enim adipiscing phasellus vitae et. Augue quis suspendisse phasellus libero eros. Amet lobortis volutpat velit augue blandit habitant. In nulla vitae volutpat elementum. Tempor nisi platea pharetra ut at egestas risus. Consequat urna velit adipiscing quam sit id metus. Pretium eu sed varius sit porttitor diam tincidunt. Nibh fermentum malesuada accumsan vulputate faucibus urna adipiscing sed eget. Volutpat placerat quam amet congue commodo ut integer nulla. Interdum sit morbi porttitor sed massa sit maecenas a. Tempor nec lacus euismod ullamcorper auctor turpis eu. Orci mattis nibh eget eget arcu neque suspendisse eleifend auctor. Vitae placerat fusce urna mauris donec at sit diam."
  },
  {
    keyword: "Reality Checks",
    description: "Lorem ipsum dolor sit amet consectetur. Sed pretium ipsum ultricies faucibus sit fermentum ultrices vitae laoreet. Enim adipiscing phasellus vitae et. Augue quis suspendisse phasellus libero eros. Amet lobortis volutpat velit augue blandit habitant. In nulla vitae volutpat elementum. Tempor nisi platea pharetra ut at egestas risus. Consequat urna velit adipiscing quam sit id metus. Pretium eu sed varius sit porttitor diam tincidunt. Nibh fermentum malesuada accumsan vulputate faucibus urna adipiscing sed eget. Volutpat placerat quam amet congue commodo ut integer nulla. Interdum sit morbi porttitor sed massa sit maecenas a. Tempor nec lacus euismod ullamcorper auctor turpis eu. Orci mattis nibh eget eget arcu neque suspendisse eleifend auctor. Vitae placerat fusce urna mauris donec at sit diam."
  },
  {
    keyword: "Game Speed",
    description: "Lorem ipsum dolor sit amet consectetur. Sed pretium ipsum ultricies faucibus sit fermentum ultrices vitae laoreet. Enim adipiscing phasellus vitae et. Augue quis suspendisse phasellus libero eros. Amet lobortis volutpat velit augue blandit habitant. In nulla vitae volutpat elementum. Tempor nisi platea pharetra ut at egestas risus. Consequat urna velit adipiscing quam sit id metus. Pretium eu sed varius sit porttitor diam tincidunt. Nibh fermentum malesuada accumsan vulputate faucibus urna adipiscing sed eget. Volutpat placerat quam amet congue commodo ut integer nulla. Interdum sit morbi porttitor sed massa sit maecenas a. Tempor nec lacus euismod ullamcorper auctor turpis eu. Orci mattis nibh eget eget arcu neque suspendisse eleifend auctor. Vitae placerat fusce urna mauris donec at sit diam."
  },
  {
    keyword: "Visible Clock",
    description: "Lorem ipsum dolor sit amet consectetur. Sed pretium ipsum ultricies faucibus sit fermentum ultrices vitae laoreet. Enim adipiscing phasellus vitae et. Augue quis suspendisse phasellus libero eros. Amet lobortis volutpat velit augue blandit habitant. In nulla vitae volutpat elementum. Tempor nisi platea pharetra ut at egestas risus. Consequat urna velit adipiscing quam sit id metus. Pretium eu sed varius sit porttitor diam tincidunt. Nibh fermentum malesuada accumsan vulputate faucibus urna adipiscing sed eget. Volutpat placerat quam amet congue commodo ut integer nulla. Interdum sit morbi porttitor sed massa sit maecenas a. Tempor nec lacus euismod ullamcorper auctor turpis eu. Orci mattis nibh eget eget arcu neque suspendisse eleifend auctor. Vitae placerat fusce urna mauris donec at sit diam."
  }
];

const ResponsibleGamingPage = () => {
  const { MOBILE_WIDTH, HEADER_MIN_WIDTH } = useResponsiveQueries();

  return (
    <div className='rg-main-container'>
      <div className='rg-row1-container'>
        <div className='row-title' style={{color: '#FFFFFF', height: 115, fontSize:'clamp(40px, 5.5vw, 96px)'}}>
          RESPONSIBLE GAMING
        </div>
      </div>
      <div className='rg-row2-container'>
        <div className='row1'>
          <div className='row-title' style={{color: '#0D65D9', fontSize: 'clamp(40px, 5.5vw, 96px)', marginBottom: 10}}>
            GAME SECURITY, FAIRNESS AND ACCREDITATION?
          </div>
          <div style={{textAlign: MOBILE_WIDTH ? 'center': 'left', fontSize: 'clamp(16px, 1.5vw, 24px)', marginBottom: 'clamp(0px, 3vw, 64px)', maxWidth: MOBILE_WIDTH ? '100%' : '86.66%'}} className='description'>
            Lorem ipsum dolor sit amet consectetur. Sed pretium ipsum ultricies faucibus sit fermentum ultrices vitae laoreet. Enim adipiscing phasellus vitae et. Augue quis suspendisse phasellus libero eros. Amet lobortis volutpat velit augue blandit habitant. In nulla vitae volutpat elementum. Tempor nisi platea pharetra ut at egestas risus. Consequat urna velit adipiscing quam sit id metus. Pretium eu sed varius sit porttitor diam tincidunt. Nibh fermentum malesuada accumsan vulputate faucibus urna adipiscing sed eget. Volutpat placerat quam amet congue commodo ut integer nulla. Interdum sit morbi porttitor sed massa sit maecenas a. Tempor nec lacus euismod ullamcorper auctor turpis eu. Orci mattis nibh eget eget arcu neque suspendisse eleifend auctor. Vitae placerat fusce urna mauris donec at sit diam.
          </div>
        </div>
        <div className='row2'>
          <img style={{width: MOBILE_WIDTH ? '100%' : 'calc(100vw - 70%)', maxWidth: MOBILE_WIDTH ? 360 : 789, margin: HEADER_MIN_WIDTH ? '16px 0px' : ''}} src={`https://101.it.com/images/SlotMachine.webp`} alt="Slot Machine" loading='lazy'/>
          <div  className='rg-column-container' style={HEADER_MIN_WIDTH ? {width: '100%'} : {}}>
            <div className='description' style={{textAlign: 'left', fontSize: 'clamp(16px, 1.5vw, 24px)'}}>
              We want people to enjoy our games for the right reason- to have fun. At <span style={{fontFamily: 'Proxima Nova Bold'}}>101 Best White-label</span> we take Responsible Gaming seriously and want to offer a source of entertainment that people over the legal age limit can enjoy in a safe, secure and fair environment. Responsible gaming is a driving force to maintain a sustainable business.
            </div>
            <br/>
            <div className='description' style={{textAlign: 'left', fontSize: 'clamp(16px, 1.5vw, 24px)'}}>
              As a licensed casino software provider in many jurisdictions, <span style={{fontFamily: 'Proxima Nova Bold'}}>101 Best White-label</span> ensures that all of our games are developed to the highest standards and are fully compliant in meeting the requirements set out by the regulating authorities. It is our strategy to enter regulated markets and we fully support new regulations which place important responsible gaming criteria on both us as suppliers and operators (the casinos).
            </div>
            <br/>
            <div className='description' style={{textAlign: 'left', fontSize: 'clamp(16px, 1.5vw, 24px)'}}>
             <span style={{fontFamily: 'Proxima Nova Bold'}}>101 Best White-label</span> as a software provider (B2B), has no direct relationship with the players. However we believe it is our responsibility to provide our customers (the operators) all of the tools and support they need when it comes to responsible gaming.
            </div>
          </div>
        </div>
      </div>
      <div className='rg-row3-container'>
        <div className='row-title' style={{color: '#FFFFFF', width: '72%', fontSize:'clamp(40px, 5.5vw, 96px)'}}>
          SELF CONTROL AND SELF-PROTECTION
        </div>
        <ul
          className="description"
          style={{
            fontSize:'clamp(16px, 1.5vw, 24px)',
            width: "86.66%",
            textAlign: "left",
            color: "#FFFFFF",
          }}
        >
          {reminderList.map((item, index) => (
            <div style={{marginRight: 16}} key={index}>
              <li>
                <span style={{fontFamily: 'Proxima Nova Bold'}}>{item.keyword}: </span>
                {item.description}
              </li>
              {index < reminderList.length - 1 && <br/>}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ResponsibleGamingPage;