import React from 'react';
import { useResponsiveQueries } from '../../utils/constants';

const HomeRow1 = () => {
  const { MOBILE_WIDTH, HEADER_MIN_WIDTH } = useResponsiveQueries();

  return (
    <div className='home-row1-container'>
      {!HEADER_MIN_WIDTH && <img className='image1' src={`https://101.it.com/images/White-Cat.webp`} alt='WhiteCat' loading='lazy'/>}
      {!MOBILE_WIDTH && <img className='image2' src={`https://101.it.com/images/Totem.webp`} alt='Totem' loading='lazy'/>}
      {MOBILE_WIDTH && <img className='image5' src={`https://101.it.com/images/Leaves-M.webp`} alt='Leaves' loading='lazy'/>}
      {!HEADER_MIN_WIDTH && <img className='image3' src={`https://101.it.com/images/Leaf2.webp`} alt='Leaf' loading='lazy'/>}
      <img className='image4' src={`https://101.it.com/images/Char1.webp`} alt='Character 1' loading='lazy'/>
      <div className='label'>
        <div className='row-title' style={{color: '#0D65D9', fontSize: 'clamp(40px, 7vw, 96px)'}}>
          BEST WAYS TO SUCCESS
        </div>
        <div className='row-subtitle' style={{fontSize: 'clamp(24px, 2.5vw, 32px)'}}>
          Start Your Dream Now
        </div>
      </div>
    </div>
  );
};

export default HomeRow1;