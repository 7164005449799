import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Slide } from '@mui/material';
import './Components.css';
import { useResponsiveQueries } from '../utils/constants';

const buttons = [
  { src: 'HomeButton', alt: 'Home', path: '/' },
  { src: 'ResponsibleGamingButton', alt: 'Responsible Gaming', path: '/responsible-gaming' },
  { src: 'AboutButton', alt: 'About', path: '/about-us' },
  { src: 'ContactButton', alt: 'Contact', path: '/contact-us' },
  { src: 'GetStartedButton', alt: 'Get Started', path: '/get-started' },
];

const FloatingButtons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { HEADER_MIN_WIDTH, HEADER_MAX_WIDTH } = useResponsiveQueries();
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > (!HEADER_MAX_WIDTH ? 99 : 64)) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure viewport is scrolled to the top
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, HEADER_MAX_WIDTH]);

  return (
    <Slide direction="left" in={isVisible && !HEADER_MIN_WIDTH} mountOnEnter unmountOnExit>
      <div className='floating-buttons-container'>
        {buttons.map((button, index) => (
          <img
            key={index}
            onClick={() => navigate(button.path)}
            className='icon'
            src={`https://101.it.com/images/${button.src}.svg`}
            alt={button.alt}
            loading='lazy'
          />
        ))}
      </div>
    </Slide>
  );
};

export default FloatingButtons;