import React from "react";

const SocialMediaButtons = (props) => {
  var customWidth = props.width;
  
  const handleOpenLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div style={{width: customWidth}} className="social-media-button-container">
      <img 
        className="social-media-button"
        src={`https://101.it.com/images/Facebook.svg`}
        alt="Facebook" 
        loading='lazy'
        onClick={() => handleOpenLink('https://www.facebook.com')} 
      />
      <img 
        className="social-media-button"
        src={`https://101.it.com/images/LinkedIn.svg`}
        alt="LinkedIn" 
        loading='lazy'
        onClick={() => handleOpenLink('https://www.linkedin.com')} 
      />
      <img 
        className="social-media-button"
        src={`https://101.it.com/images/X.svg`}
        alt="X" 
        loading='lazy'
        onClick={() => handleOpenLink('https://www.twitter.com')} 
      />
      <img 
        className="social-media-button"
        src={`https://101.it.com/images/Youtube.svg`} 
        alt="YouTube" 
        loading='lazy'
        onClick={() => handleOpenLink('https://www.youtube.com')} 
      />
      <img 
        className="social-media-button"
        src={`https://101.it.com/images/Instagram.svg`}
        alt="Instagram" 
        loading='lazy'
        onClick={() => handleOpenLink('https://www.instagram.com')} 
      />
    </div>
  );
};

export default SocialMediaButtons;