import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import FloatingButtons from './components/FloatingButtons';
import LiveChat from './components/LiveChat';
import HomePage from './pages/home/HomePage'
import ResponsibleGamingPage from './pages/responsibleGaming/ResponsibleGamingPage';
import AboutPage from './pages/about/AboutPage';
import ContactPage from './pages/contact/ContactPage';
import GetStartedPage from './pages/getStarted/GetStartedPage';

function App() {
  return (
    <div>
      <Header/>
      <FloatingButtons/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/responsible-gaming" element={<ResponsibleGamingPage/>}/>
        <Route path="/about-us" element={<AboutPage/>}/>
        <Route path="/get-started" element={<GetStartedPage/>}/>
        <Route path="/contact-us" element={<ContactPage/>}/>
      </Routes>
      <LiveChat/>
      <Footer/>
    </div>
  );
}

export default App;
