import React, { useState, useCallback } from 'react';
import './GetStartedPage.css';
import { Button, Dialog, Grow } from '@mui/material';
import { useResponsiveQueries } from '../../utils/constants';

const DesktopUIDesign = React.memo((props) => {
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const [open, setOpen] = useState(false);
  const { src, alt, desktopUIDesign: selectedDesktopUIDesign, onClick } = props;
  const isSelected = selectedDesktopUIDesign?.alt === alt;

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const showTemplateForm = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div className="desktop-ui-design">
      <div className="ui-design-label">{alt}</div>
      <div 
        style={(isSelected && !MOBILE_WIDTH) ? {maxWidth: 488, width: '100%', pointerEvents: 'none'} : MOBILE_WIDTH ? {margin: 0} : {maxWidth: 488}} 
        className="image-container"
      >
        <div style={{margin: 0}} className='ui-design-image-container'>
          <img
            className="ui-design-image" 
            src={`https://101.it.com/images/${src}.webp`} 
            alt={alt}
            loading='lazy'
            onClick={handleClickOpen} 
          />
          <div className="preview-label">Preview</div>
        </div>
        {isSelected && <img className='check-icon' style={{top: '-6%'}} src={`https://101.it.com/images/Check.webp`} alt='Check' loading='lazy'/>}
      </div>
      <Button 
        id="action-button"
        disabled={isSelected}
        style={{maxWidth: 320, marginTop: '4%'}}
        onClick={showTemplateForm}
      >
        Select
      </Button>
      <Dialog 
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }} 
        TransitionComponent={Grow}
        transitionDuration={500}
        onClose={handleClose} 
        maxWidth="xl"
        open={open}
      >
        <img style={!MOBILE_WIDTH ? {height: '90vh'} : {}} src={`https://101.it.com/images/${src}.webp`}  alt={alt} loading='lazy'/>
      </Dialog>
    </div>
  );
});

export default DesktopUIDesign;