import { useMediaQuery } from '@mui/material';


// Custom hook for reactive media queries
export const useResponsiveQueries = () => {
  const MOBILE_WIDTH = useMediaQuery('(max-width: 768px)');
  const HEADER_MIN_WIDTH = useMediaQuery('(max-width: 1024px)');
  const HEADER_MAX_WIDTH = useMediaQuery('(max-width: 1299px)');
  
  return {
    MOBILE_WIDTH,
    HEADER_MIN_WIDTH,
    HEADER_MAX_WIDTH,
  };
};