import React from 'react';
import AboutRow1 from './AboutRow1';
import AboutRow2 from './AboutRow2';
import AboutRow3 from './AboutRow3';

const About = () => {
  return (
    <div>
      <AboutRow1/>
      <AboutRow2/>
      <AboutRow3/>
    </div>
  );
}

export default About;