import React from 'react';
import './AboutPage.css';
import { useResponsiveQueries } from '../../utils/constants';

const AboutRow1 = () => {
  const { MOBILE_WIDTH } = useResponsiveQueries();

  return (
    <div className='row1-container'>
      {!MOBILE_WIDTH && 
      <div style={{position: 'relative'}}>
        <img className='image' src={`https://101.it.com/images/Girl2.webp`} alt='Girl 2' loading='lazy'/>
      </div>}
      <div className='label'>
        <div className='row-title' style={{fontSize: 'clamp(40px, 5.5vw, 96px)' , color: '#0D65D9'}}>
          ABOUT US
        </div>
        <div className='row-subtitle' style={{fontSize: 'clamp(16px, 2vw, 32px)'}}>
          Lorem ipsum dolor sit amet consectetur.
        </div>
      </div>
    </div>
  );
};

export default AboutRow1;