import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, MenuItem, Slide} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useResponsiveQueries } from '../utils/constants';

const languages = [
  { src: 'English', alt: 'English' },
  { src: 'Vietnam', alt: 'Tiếng Việt' },
  { src: 'Thailand', alt: 'ไทย' },
  { src: 'China', alt: '简体中文' },
];

const LanguageDropdown = () => {
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [isVisible, setIsVisible] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleScroll = () => {
    if (window.scrollY > 99) {
      setIsVisible(MOBILE_WIDTH);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, MOBILE_WIDTH]);

  const handleClick = (event) => {
    if (anchorEl) {
      // If the dropdown is open, close it
      setAnchorEl(null);
    } else {
      // If the dropdown is closed, open it
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (language) => {
    setSelectedLanguage(language);
    handleClose();
  };

  return (
    <div className='language-dropdown-container'>
      <div className='flag-container' onClick={handleClick}>
        {selectedLanguage && <img className='icon' src={`https://101.it.com/images/${selectedLanguage.src}.svg`} alt={selectedLanguage.alt} loading='lazy'/>}
        <ArrowDropDownIcon
          style={{
            transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }} 
        />
      </div>
      {isVisible &&
      <Menu
        id='flag-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        transitionDuration={300}
        disableScrollLock
      >
        {languages.map((language) => (
          <MenuItem
            className='item'
            key={language.alt}
            onClick={() => handleMenuItemClick(language)}
          >
            <img src={`https://101.it.com/images/${language.src}.svg`} alt={language.alt} className='option-icon' loading='lazy'/>
            <div className='option-label'>
              {language.alt}
            </div>
          </MenuItem>
        ))}
      </Menu>}
    </div>
  );
}

export default LanguageDropdown;