import React from 'react';
import { Divider } from '@mui/material';
import SocialMediaButtons from './SocialMediaButtons';
import { useResponsiveQueries } from '../utils/constants';

const Footer = () => {
  const { MOBILE_WIDTH } = useResponsiveQueries();

  return (
    <div className='footer-main-container'>
      <div className='footer-row1-container'>
        <div className='row'>
          <img style={{height: '100%'}} src={`https://101.it.com/images/Sigma.webp`} alt='Sigma' loading='lazy'/>
          <div className='column'>
            <div className='label'>
              BEST PLATFORM PROVIDER NEWCOMER 2024
            </div>
            <img style={{width: 'clamp(291px, 59vw, 409px)'}} src={`https://101.it.com/images/Vector.webp`} alt='Vector' loading='lazy'/>
          </div>
        </div>
      </div>
      <div className='footer-row2-container'>
        <img className='logo' src={`https://101.it.com/images/Logo-Best-Whitelabel.webp`} alt='BestWhiteLabel' loading='lazy'/>
        <div className='label'>
          Lorem ipsum dolor sit amet consectetur. Leo et nunc amet eget sed consectetur. Facilisis aliquet erat nisi feugiat arcu tellus aliquam. Dui nibh condimentum nibh arcu. Sit justo suscipit ornare enim urna. Maecenas quam lobortis turpis viverra ac. Dolor malesuada velit purus tortor pretium. Ornare sit fames euismod tincidunt convallis viverra diam enim vulputate. Risus fringilla ullamcorper eget id aliquam laoreet faucibus. Lacinia dolor hac orci accumsan at eget vitae venenatis. Lorem ipsum dolor sit amet consectetur. Leo et nunc amet eget sed consectetur. Facilisis aliquet erat nisi feugiat arcu tellus aliquam. Dui nibh condimentum nibh arcu. Sit justo suscipit ornare enim urna. Maecenas quam lobortis turpis viverra ac. Dolor malesuada velit purus tortor pretium. Ornare sit fames euismod tincidunt convallis viverra diam enim vulputate. Risus fringilla ullamcorper eget id aliquam laoreet faucibus. Lacinia dolor hac orci accumsan at eget vitae venenatis.
        </div>
      </div>
      <div className='footer-row3-container'>
        <div className='column1'>
          Follow us
          <SocialMediaButtons/>
        </div>
        {MOBILE_WIDTH && <Divider style={{width: '-webkit-fill-available', background: '#505050', marginBottom: '24px'}}/>}
        <div className='column2'>
          <img style={{width: '5%', maxWidth: 32}} src={`https://101.it.com/images/Plus18Mini.svg`} alt='Plus18' loading='lazy'/>
          <img style={{width: '27%', maxWidth: 179}} src={`https://101.it.com/images/GamStopMini.svg`} alt='GamStop' loading='lazy'/>
          <img style={{width: '36%', maxWidth: 247}} src={`https://101.it.com/images/GambleAwareMini.svg`} alt='GambleAware' loading='lazy'/>
          <img style={{width: '17%', maxWidth: 112}} src={`https://101.it.com/images/GamCareMini.svg`} alt='GamCare' loading='lazy'/>
        </div>
      </div>
      <div className='footer-row4-container'>
        Copyright © 2024 101 BW. All rights reserved.
      </div>
    </div>
  )
};

export default Footer;