import React, { useState, useEffect } from 'react';
import './Components.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Tabs, Tab, Button, Drawer, Container, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LanguageDropdown from './LanguageDropdown';
import SocialMediaButtons from './SocialMediaButtons';
import { useResponsiveQueries } from '../utils/constants';

const tabs = [
  { label: 'Home', path: '/' },
  { label: 'Responsible Gaming', path: '/responsible-gaming' },
  { label: 'About Us', path: '/about-us' },
  { label: 'Contact Us', path: '/contact-us' },
];

const Header = () => {
  const { HEADER_MIN_WIDTH, HEADER_MAX_WIDTH } = useResponsiveQueries();
  const [isGetStarted, setIsGetStarted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerControls, setDrawerControls] = useState(false);
  const tabValue = tabs.findIndex(tab => tab.path === location.pathname)

  useEffect(() => {
    if (location.pathname === '/get-started') {
      setIsGetStarted(true);
    } else {
      setIsGetStarted(false);
    }
  }, [location]);

  const handleNavigation = (value) => {
    setDrawerControls(false);
    navigate(value);
  };

  const drawerContent = (
    <div className='drawer-container'>
      <Container className='drawer-header'>
        <Container className='row'>
          <img src={`https://101.it.com/images/Logo-Best-Whitelabel.webp`} alt="BestWhiteLabel" loading='lazy' style={{ height: 32, width: 133 }} onClick={() => handleNavigation('/')}/>
          <IconButton sx={{color: '#FFFFFF'}} onClick={()=>setDrawerControls(false)}><CloseIcon/></IconButton>
        </Container>
      </Container>
      <div className='drawer-nav-container'>
        <Container className='row1'>
          <div className='nav-button-mobile'>
            {tabs.map((tab, index) => (
              <p key={index} onClick={() => handleNavigation(tab.path)}>{tab.label}</p>
            ))}
          </div>
          <Button
            style={{width:'100%', maxWidth: '345px', height: '48px', fontSize: '16px'}}
            sx={isGetStarted ? {
              pointerEvents: 'none !important',
              color: '#FFFFFF !important', 
              background: 'linear-gradient(180deg, #0D65D9 0%, #073673 100%) !important',
              '::after': {
                width: '101% !important',
              }
            } : 
            {
              '::after': {
                width: '101% !important',
              }
            }
            }
            id='get-started-button'
            onClick={() => handleNavigation('/get-started')} 
          >
            GET STARTED
          </Button>
        </Container>
        <div className='row2'>
          <Divider className='divider'/>
          <SocialMediaButtons width={'62.7%'}/>
        </div>      
      </div>
    </div>
  );

  return (
    <AppBar id='header-container'>
     <Toolbar id='row'>
        <img className='logo' onClick={() => handleNavigation('/')} src={`https://101.it.com/images/Logo-Best-Whitelabel.webp`} alt="BestWhiteLabel" loading='lazy'/>
        {!HEADER_MIN_WIDTH && 
        <Tabs id='nav-tabs' value={tabValue === -1 ? 0 : tabValue}>
          {tabs.map((tab, index) => (
            <Tab
              id='nav-button'
              sx={location.pathname === tab.path ? {pointerEvents: 'none', background: 'linear-gradient(180deg, #002556 0%, #0051BC 100%)'} : {}}
              onClick={() => handleNavigation(tab.path)}
              key={index}
              label={tab.label}
            />
          ))}
        </Tabs>}
        <div className='button-container'>
          {!HEADER_MIN_WIDTH &&
          <Button
            id='get-started-button'
            sx={isGetStarted ? 
              {
                pointerEvents: 'none !important',
                fontSize: !HEADER_MAX_WIDTH ? '18px !important' : '14px !important', 
                color: '#FFFFFF !important', 
                background: 'linear-gradient(180deg, #0D65D9 0%, #073673 100%) !important',
              } : 
              {}
            }
            onClick={() => handleNavigation('/get-started')} 
          >
            GET STARTED
          </Button>}
          <LanguageDropdown/>
          {HEADER_MIN_WIDTH && <img src={`https://101.it.com/images/MenuIcon.webp`} alt='Menu' onClick={()=>setDrawerControls(true)}/>}
        </div>
        {HEADER_MIN_WIDTH && 
        <Drawer
          anchor="right"
          open={drawerControls}
          onClose={()=>setDrawerControls(false)}
          sx={{
            '& .MuiDrawer-paper': {
                width: '100%',
                height: '100%',
                background: '#002556'
            }
          }}
        >
          {drawerContent}
        </Drawer>}
      </Toolbar> 
    </AppBar>
  )
}
export default Header;
