import React, { useState, useCallback } from 'react';
import './GetStartedPage.css';
import { Button, Dialog, Grow } from '@mui/material';
import { useResponsiveQueries } from '../../utils/constants';

const MobileUIDesign = React.memo((props) => {
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const [open, setOpen] = useState(false);
  const { src, alt, mobileUIDesign: selectedMobileUIDesign, onClick } = props;
  const isSelected = selectedMobileUIDesign?.alt === alt;

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const showTemplateForm = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div className="mobile-ui-design">
      <div className="ui-design-label">{alt}</div>
      <div style={isSelected ? {pointerEvents: 'none', margin: 0} : {margin: 0}} className="image-container">
        <div style={((isSelected) || MOBILE_WIDTH) ? {margin: 0} : {}} className='ui-design-image-container'>
          <img 
            className="ui-design-image" 
            src={`https://101.it.com/images/${src}.webp`} 
            alt={alt}
            loading='lazy'
            onClick={handleClickOpen} 
          />
          <div className="preview-label">Preview</div>
        </div>
        {isSelected && <img className='check-icon' style={{top: '-3.5%'}} src={`https://101.it.com/images/Check.webp`} alt='Check' loading='lazy'/>}
      </div>
      <Button 
        id="action-button" 
        style={{maxWidth: 226, marginTop: '9%'}}
        disabled={isSelected}
        onClick={showTemplateForm}
      >
        Select
      </Button>
      
      <Dialog 
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }} 
        TransitionComponent={Grow}
        transitionDuration={500}
        onClose={handleClose} 
        open={open}
      >
        <img style={{height: MOBILE_WIDTH ? '80vh' : '90vh'}} src={`https://101.it.com/images/${src}.webp`}  alt={alt} loading='lazy'/>
      </Dialog>
    </div>
  );
});

export default MobileUIDesign;
