import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css'
import { Button } from '@mui/material';
import { useResponsiveQueries } from '../../utils/constants';

const HomeRow2 = () => {
  const { HEADER_MIN_WIDTH, MOBILE_WIDTH } = useResponsiveQueries();
  const navigate = useNavigate();

  const handleNavigation = (value) => {
    navigate(value);
  };

  return (
    <div className='home-row2-container'>
      {!HEADER_MIN_WIDTH && <img className='image' src={`https://101.it.com/images/Girl1.webp`} alt='Girl1' loading='lazy'/>}
      <div className='home-row2-description'>
          <div className='row-title' style={{color: '#0D65D9', fontSize: 'clamp(40px, 7vw, 96px)', height: 'clamp(40px, 7vw, 96px)'}}>
            RESPONSIBLE GAMING
          </div>
          <div className='home-row2-logo-container'>
            <img style={{width: '24.6%', maxWidth: 224}} src={`https://101.it.com/images/GamStop.webp`} alt='Gam Stop' loading='lazy'/>
            <img style={{width: '40.7%', maxWidth: 370}} src={`https://101.it.com/images/GambleAware.webp`} alt='Gamble Aware' loading='lazy'/>
            <img style={{width: '21.4%', maxWidth: 195}} src={`https://101.it.com/images/GamCare.webp`} alt='Gam Care' loading='lazy'/>
          </div>
        <div className='description' style={{textAlign: 'justify', height: 'auto', maxHeight: 312, fontSize: 'clamp(16px, 2vw, 24px)'}}>
          Lorem ipsum dolor sit amet consectetur. Leo amet posuere molestie est arcu. Phasellus fermentum ut tincidunt purus lectus velit. Morbi commodo tincidunt pretium amet netus tortor. Id vitae id a est. Id quam sollicitudin nibh enim cras tristique semper leo. Et nam gravida vestibulum viverra. Dolor quisque enim amet faucibus sed blandit lorem ultrices. Commodo venenatis nunc nunc vulputate ipsum et sit tristique. Arcu gravida eget quis vitae integer viverra ac. Quam tristique quis sem proin vitae enim lobortis lectus. Sed consectetur volutpat velit a arcu sem dignissim. Cursus morbi amet nam amet iaculis viverra. Neque dictumst et purus pellentesque tincidunt laoreet. Mi sit imperdiet ut erat orci vel.
        </div>
        <Button 
          id='action-button' 
          sx={{width: '100%', maxWidth: MOBILE_WIDTH ? '361px' : '184px', zIndex: 1}}
          onClick={() => handleNavigation('/responsible-gaming')}
        >
          Find out more
        </Button>
      </div>
      {!HEADER_MIN_WIDTH &&
      <img className='image-bg' src={`https://101.it.com/images/Plus18.webp`} alt='Plus18' loading='lazy'/>}
    </div>
  );
};

export default HomeRow2;