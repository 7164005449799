import React, {useState} from 'react';
import './ContactPage.css'
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useResponsiveQueries } from '../../utils/constants';

const interestData = [
  { title: 'SALES', src: 'QR', email: 'sales@101bw.com' },
  { title: 'PRESS', src: 'QR', email: 'press@101bw.com' },
  { title: 'WORK', src: 'QR', email: 'work@101bw.com' },
  { title: 'AFFILIATE', src: 'QR', email: 'affiliate@101bw.com' },
  { title: 'TELEGRAM', src: 'QR', email: '@telegram' },
];

const ContactPage = () => {
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const [currentIndex, setCurrentIndex] = useState(0);
  const transition = 'transform 0.5s ease-in-out';

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < interestData.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  return (
    <div className='contact-container'>
      <div className='row1'>
        <div className='row-title' style={{color: '#FFFFFF', fontSize: 'clamp(40px, 5.5vw, 96px)'}}>
          CONTACT US
        </div>
        <div className='row-subtitle' style={{color: '#FFFFFF', fontSize: 'clamp(16px, 2vw, 32px)'}}>
          Lorem ipsum dolor sit amet consectetur.
        </div>
      </div>
      <div className='row2'>
        <div className='row-title' style={{color: '#0D65D9', fontSize: 'clamp(40px, 5.5vw, 96px)', marginBottom: '24px'}}>
          AREA OF INTEREST?
        </div>
        <div className='interest-container'>
          {MOBILE_WIDTH && currentIndex > 0 && (
            <IconButton className='prev-button' onClick={handlePrev}>
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <div style={MOBILE_WIDTH ? {transform: `translateX(-${currentIndex * 100}%)`, transition: transition,} : {}} className='qr-row-container'>
            {interestData.map((item, index) => (
              <div className='qr-container' key={index}>
                <div style={{fontSize: 'clamp(32px, 3vw, 40px)'}} className='qr-title'>{item.title}</div>
                <img src={`https://101.it.com/images/${item.src}.webp`} alt={`${item.title} QR`} loading='lazy'/>
                <div className='qr-email'>{item.email}</div>
              </div>
            ))}
          </div>
          {MOBILE_WIDTH && currentIndex < interestData.length - 1 && (
            <IconButton className='next-button' onClick={handleNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;