import React, { useState, useCallback } from "react";
import './GetStartedPage.css'
import { Button, Grid } from '@mui/material';
import MobileUIDesign from "./MobileUIDesign";
import DesktopUIDesign from "./DesktopUIDesign";
import GameLobbyUIDesign from "./GameLobbyUIDesign";
import TemplateForm from "../../components/TemplateForm";
import { useResponsiveQueries } from '../../utils/constants';

const mobileUIDesigns = [
  {src: 'MUI1', alt: 'Mobile UI 01'},
  {src: 'MUI2', alt: 'Mobile UI 02'},
  {src: 'MUI3', alt: 'Mobile UI 03'},
  {src: 'MUI4', alt: 'Mobile UI 04'},
  {src: 'MUI5', alt: 'Mobile UI 05'},
  {src: 'MUI6', alt: 'Mobile UI 06'},
  {src: 'MUI7', alt: 'Mobile UI 07'},
  {src: 'MUI8', alt: 'Mobile UI 08'},
  {src: 'MUI9', alt: 'Mobile UI 09'},
  {src: 'MUI10', alt: 'Mobile UI 10'},
];

const desktopUIDesigns = [
  {src: 'DUI1', alt: 'Desktop UI 01'},
  {src: 'DUI2', alt: 'Desktop UI 02'},
  {src: 'DUI3', alt: 'Desktop UI 03'},
  {src: 'DUI4', alt: 'Desktop UI 04'},
  {src: 'DUI5', alt: 'Desktop UI 05'},
  {src: 'DUI6', alt: 'Desktop UI 06'},
  {src: 'DUI7', alt: 'Desktop UI 07'},
  {src: 'DUI8', alt: 'Desktop UI 08'},
  {src: 'DUI9', alt: 'Desktop UI 09'},
  {src: 'DUI10', alt: 'Desktop UI 10'},
];

const gameLobbyUIDesigns = [
  {src: 'GLSportsUI1', alt: 'Sports Lobby UI 01', category: 'SPORTSBOOK'},
  {src: 'GLSportsUI2', alt: 'Sports Lobby UI 02', category: 'SPORTSBOOK'},
  {src: 'GLSportsUI3', alt: 'Sports Lobby UI 03', category: 'SPORTSBOOK'},
  {src: 'GLCasinoUI1', alt: 'Casino Lobby UI 01', category: 'LIVE CASINO'},
  {src: 'GLCasinoUI2', alt: 'Casino Lobby UI 02', category: 'LIVE CASINO'},
  {src: 'GLCasinoUI3', alt: 'Casino Lobby UI 03', category: 'LIVE CASINO'},
  {src: 'GLSlotUI1', alt: 'Slot Lobby UI 01', category: 'SLOT GAMES'},
  {src: 'GLSlotUI2', alt: 'Slot Lobby UI 02', category: 'SLOT GAMES'},
  {src: 'GLSlotUI3', alt: 'Slot Lobby UI 03', category: 'SLOT GAMES'},
  {src: 'GLESportsUI1', alt: 'ESports Lobby UI 01', category: 'ESPORTS'},
  {src: 'GLESportsUI2', alt: 'ESports Lobby UI 02', category: 'ESPORTS'},
  {src: 'GLESportsUI3', alt: 'ESports Lobby UI 03', category: 'ESPORTS'},
  {src: 'GLFishingUI1', alt: 'Fishing Lobby UI 01', category: 'FISHING GAME'},
  {src: 'GLFishingUI2', alt: 'Fishing Lobby UI 02', category: 'FISHING GAME'},
  {src: 'GLFishingUI3', alt: 'Fishing Lobby UI 03', category: 'FISHING GAME'},
  {src: 'GLLotteryUI1', alt: 'Lottery Lobby UI 01', category: 'LOTTERY'},
  {src: 'GLLotteryUI2', alt: 'Lottery Lobby UI 02', category: 'LOTTERY'},
  {src: 'GLLotteryUI3', alt: 'Lottery Lobby UI 03', category: 'LOTTERY'},
  {src: 'GLChessUI1', alt: 'Chess Lobby UI 01', category: 'CHESS GAME'},
  {src: 'GLChessUI2', alt: 'Chess Lobby UI 02', category: 'CHESS GAME'},
  {src: 'GLChessUI3', alt: 'Chess Lobby UI 03', category: 'CHESS GAME'},
];

// Grouping UI designs by category
const groupedUIDesigns = gameLobbyUIDesigns.reduce((acc, design) => {
  const { category } = design;
  if (!acc[category]) {
    acc[category] = [];
  }
  acc[category].push(design);
  return acc;
}, {});

const GetStartedPage = React.memo(() => {
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const [selectedButton, setSelectedButton] = useState('Mobile UI');
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [selectedMobileUIDesign, setSelectedMobileUIDesign] = useState(null);
  const [selectedDesktopUIDesign, setSelectedDesktopUIDesign] = useState(null);
  const [selectedGameLobbyUIDesigns, setSelectedGameLobbyUIDesigns] = useState(
    Object.keys(groupedUIDesigns).reduce((acc, category) => {
      acc[category] = groupedUIDesigns[category][0]; // Set the first design in each category as the initial value
      return acc;
    }, {})
  );

  const handleSwitchUI = useCallback((buttonName) => {
    setSelectedButton(buttonName);
    setShowTemplateForm(!MOBILE_WIDTH && showTemplateForm);
  }, [MOBILE_WIDTH, showTemplateForm]);

  const handleShowTemplateForm = useCallback((show) => {
    setShowTemplateForm(show);
  }, []);

  const handleSelectMobileUIDesign = useCallback((design) => {
    setSelectedMobileUIDesign(design);
    handleShowTemplateForm(true);
  }, [handleShowTemplateForm]);

  const handleSelectDesktopUIDesign = useCallback((design) => {
    setSelectedDesktopUIDesign(design);
    handleShowTemplateForm(true);
  }, [handleShowTemplateForm]);

  const handleSelectGameLobbyUIDesign = useCallback((design) => {
    setSelectedGameLobbyUIDesigns(prevState => ({
      ...prevState,
      [design.category]: design,
    }));
    handleShowTemplateForm(true);
  }, [handleShowTemplateForm]);

  const handleRemoveOrBrowseUI = useCallback((category) => {
    if (category === 'mobile') {
      selectedMobileUIDesign ? setSelectedMobileUIDesign(null) : handleSwitchUI('Mobile UI');
    }
    else if (category === 'desktop') {
      selectedDesktopUIDesign ? setSelectedDesktopUIDesign(null) : handleSwitchUI('Desktop UI');
    } else {
      handleSwitchUI('Game Lobby UI');
    }
  }, [handleSwitchUI, selectedDesktopUIDesign, selectedMobileUIDesign]);

  return (
    <div className="main-container">
      <div className="switch-ui-button-container">
        <Button 
          id="switch-ui-button" 
          sx={selectedButton === 'Mobile UI' ? {pointerEvents: 'none', color: '#FFFFFF', background: '#0D65D9',
          } : {}} 
          onClick={() => handleSwitchUI('Mobile UI')} 
        >
          Mobile UI
        </Button>
        <Button 
          id="switch-ui-button" 
          sx={selectedButton === 'Desktop UI' ? {pointerEvents: 'none', color: '#FFFFFF', background: '#0D65D9',
          } : {}} 
          style={MOBILE_WIDTH ? {margin: '0px 8px'} : {}}
          onClick={() => handleSwitchUI('Desktop UI')} 
        >
          Desktop UI
        </Button>
        <Button 
          id="switch-ui-button" 
          sx={selectedButton === 'Game Lobby UI' ? {pointerEvents: 'none', color: '#FFFFFF', background: '#0D65D9',
          } : {}} 
          onClick={() => handleSwitchUI('Game Lobby UI')} 
        >
          {!MOBILE_WIDTH ? 'Game Lobby UI' : 'Game Lobby'}
        </Button> 
      </div>
      {selectedButton === 'Mobile UI' && 
      <Grid container item style={showTemplateForm ? {right: '561px', transition: 'right 0.5s ease-in-out'} : {}} id="mobile-ui-design-container">
        {mobileUIDesigns.map((design, index) => (
          <Grid lg={2.4} md={3} sm={4} xs={6} sx={{marginBottom: '45px', textAlign: '-webkit-center'}} item key={index}>
            <MobileUIDesign 
              src={design.src} 
              alt={design.alt} 
              mobileUIDesign={selectedMobileUIDesign}
              onClick={() => handleSelectMobileUIDesign(design)}
            />
          </Grid>
        ))}
      </Grid>}
      {selectedButton === 'Desktop UI' && 
      <Grid container item style={showTemplateForm ? {right: '616px', transition: 'right 0.5s ease-in-out'} : {}} id="desktop-ui-design-container">
        {desktopUIDesigns.map((design, index) => (
          <Grid sx={{width: '46%', marginBottom: 'clamp(25px, 4vw, 40px)'}} item key={index}>
            <DesktopUIDesign 
              key={index} 
              src={design.src} 
              alt={design.alt} 
              desktopUIDesign={selectedDesktopUIDesign}
              onClick={() => handleSelectDesktopUIDesign(design)}
            />
          </Grid>
        ))}
      </Grid>}
      {selectedButton === 'Game Lobby UI' && 
      <Grid container item style={showTemplateForm ? {right: '550px', transition: 'right 0.5s ease-in-out'} : {}} id="game-lobby-ui-design-container">
        {Object.keys(groupedUIDesigns).map((category) => (
          <div key={category} style={{display: 'contents'}}>
            <div className="category-label">{category}</div> {/* Category title */}
            {groupedUIDesigns[category].map((design, index) => (
              <Grid xs={6} xl={4} lg={4} sx={{display: 'flex', justifyContent: 'center', marginBottom: 'clamp(25px, 4vw, 40px)'}} item key={index}>
                <GameLobbyUIDesign
                  key={index}
                  src={design.src}
                  alt={design.alt}
                  category={category}
                  gameLobbyUIDesigns={selectedGameLobbyUIDesigns}
                  onClick={() => handleSelectGameLobbyUIDesign(design)}
                />
              </Grid>
            ))}
          </div>
        ))}
      </Grid>}
      <TemplateForm 
        showTemplateForm={showTemplateForm}
        mobileUIDesign={selectedMobileUIDesign}
        desktopUIDesign={selectedDesktopUIDesign}
        gameLobbyUIDesigns={selectedGameLobbyUIDesigns}
        onClick={() => handleShowTemplateForm(false)}
        onRemoveOrBrowseUI={handleRemoveOrBrowseUI}
      />
    </div>
  );
});

export default GetStartedPage;