import React from 'react';
import './AboutPage.css';

const AboutRow2 = () => {
  return (
    <div className='row2-container'>
      <div className='row1'>
        <div className='label'>
          <div className='row-title' style={{color: '#0D65D9', fontSize:'clamp(40px, 5.5vw, 96px)'}}>
            GROWTH CULTURE
          </div>
          <div style={{fontSize:'clamp(16px, 1.5vw, 24px)', maxWidth: 1300}} className='description'>
            Lorem ipsum dolor sit amet consectetur. Nibh nulla vulputate cursus gravida turpis praesent. Sed ac sit aliquet neque netus. Id dignissim eget malesuada volutpat. Vitae varius mauris id egestas etiam. Euismod magna imperdiet convallis viverra molestie. Mauris mauris scelerisque tristique tortor nisl commodo adipiscing. Condimentum quisque consectetur morbi morbi sed. Et tincidunt parturient gravida dictum maecenas platea.
          </div>
        </div>
      </div>
      <div className='row2'>
        <img className='image' src={`https://101.it.com/images/Laptop.webp`} alt='Laptop' loading='lazy'/>
        <div className='label'>
          <div className='row-title' style={{color: '#F2F2F2', fontSize:'clamp(40px, 5.5vw, 96px)'}}>
            QUALITY IS WHAT WE DO
          </div>
          <div className='description' style={{width: '95%', color: '#FFFFFF', fontSize:'clamp(16px, 1.5vw, 24px)'}}>
            Lorem ipsum dolor sit amet consectetur. Nibh nulla vulputate cursus gravida turpis praesent. Sed ac sit aliquet neque netus. Id dignissim eget malesuada volutpat. Vitae varius mauris id egestas etiam. Euismod magna imperdiet convallis viverra molestie. Mauris mauris scelerisque tristique tortor nisl commodo adipiscing. Condimentum quisque consectetur morbi morbi sed. Et tincidunt parturient gravida dictum maecenas platea.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRow2;