import React, { useState } from "react";
import './Components.css';
import { TextField, Autocomplete, Chip, Button, Slide } from "@mui/material";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useResponsiveQueries } from '../utils/constants';

const languages = [
  { abbr: 'EN', name: 'English' },
  { abbr: 'VN', name: 'Tiếng Việt' },
  { abbr: 'TH', name: 'ไทย' },
  { abbr: 'CN', name: '简体中文' },
];

const currencies = [
  { abbr: 'USD', name: 'US Dollar' },
  { abbr: 'VND', name: 'Vietnam Dong' },
  { abbr: 'THB', name: 'Thai Baht' },
  { abbr: 'CNY', name: 'Chinese Yuan' },
];

const countryTelCode = [
  { code: '+84', name: 'Vietnam' },
  { code: '+66', name: 'Thailand' },
  { code: '+86', name: 'China' },
];

const TemplateForm = (props) => {
  const { MOBILE_WIDTH } = useResponsiveQueries();
  const [websiteName, setWebsiteName] = useState('');
  const [websiteId, setWebsiteId] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [defaultTelCountry, setDefaultTelCountry] = useState(null);
  var selectedMobileUIDesign = props.mobileUIDesign;
  var selectedDesktopUIDesign = props.desktopUIDesign;
  var selectedGameLobbyUIDesigns = props.gameLobbyUIDesigns;
  var isVisible = props.showTemplateForm;

  const isDataComplete = () => {
    return (
      websiteId !== '' &&
      selectedLanguages.length > 0 &&
      websiteName !== '' &&
      defaultLanguage !== null &&
      defaultCurrency !== null &&
      defaultTelCountry !== null &&
      selectedMobileUIDesign !== null &&
      selectedDesktopUIDesign !== null &&
      selectedGameLobbyUIDesigns && Object.keys(selectedGameLobbyUIDesigns).length > 0
    );
  };

  const showTemplateForm = () => {
    props.onClick();
  };

  const handleRemoveOrBrowseUI = (category) => {
    props.onRemoveOrBrowseUI(category);
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  };

  const handleWebsiteNameChange = (event) => {
    setWebsiteName(event.target.value);
  };

  const getWebsiteId = (event) => {
    const { value } = event.target;

    // Regular expression to check for lowercase letters and numbers only
    const regex = /^[a-z0-9]*$/;

    // Validate the input value
    if (regex.test(value) || value === '') {
      setWebsiteId(value);
    } else {
      // Handle invalid input if needed
      // For example, show an error message or just ignore
    }
  };

  const handleSelectedLanguagesChange = (event, newValue) => {
    setSelectedLanguages(newValue);
  };

  const handleDefaultLanguageChange = (event, newValue) => {
    setDefaultLanguage(newValue);
  };

  const handleDefaultCurrencyChange = (event, newValue) => {
    setDefaultCurrency(newValue);
  };

  const handleDefaultTelCountryChange = (event, newValue) => {
    setDefaultTelCountry(newValue);
  };

  const downloadZipFile = () => {
    const zip = new JSZip();
    const selectedLangs = selectedLanguages.map(lang => lang.abbr);

    const agentData = 
    `module.exports = {
      locales: ['zh-CN', 'en-US', 'vi-VN'],
      defaultLocale: '${defaultLanguage.abbr}',
      defaultEcLocale: 'vi-VN',
      languages: [ ${selectedLangs.map(lang => `'${lang}'`).join(', ')} ],
      defaultCurrency: '${defaultCurrency.abbr}',
      defaultTelCountry: '${defaultTelCountry.code}',
      name: '${websiteName}',
    }`;

    const imsData = 
    `module.exports = {
      locales: ['zh-CN', 'en-US', 'vi-VN'],
      defaultLocale: '${defaultLanguage.abbr}',
      defaultEcLocale: 'vi-VN',
      languages: [ ${selectedLangs.map(lang => `'${lang}'`).join(', ')} ],
      defaultCurrency: '${defaultCurrency.abbr}',
      defaultTelCountry: '${defaultTelCountry.code}',
      name: '${websiteName}',
      htmlContent: \`<script>window.difyChatbotConfig = { baseUrl:'https://prod-check-bot.ihudba.com', token: 'bM4rF4IRz5bFITSC' }</script><script src='https://prod-check-bot.ihudba.com/embed.min.js' id='bM4rF4IRz5bFITSC' defer></script>\`
    }`;

    const ecData = 
    `var portal = require('../' + process.env.PORTAL_CODE + '/' + process.env.SERVER_ENV);

    module.exports = {
      brand: {
        code: '${websiteId}',
        name: '${websiteName}',
        title: '${websiteName}',
        favicon: 'website.ico',
        copyright: 'Copyright © ${websiteName} Reserved',
        custom: {
          style: 'default_temp',
          components: {
            Home: 'ec67',
            Header: 'ec67',
            Navigation: 'ec67',
            Footer: 'ec67',
            Sports: '${selectedGameLobbyUIDesigns['SPORTSBOOK'].alt}'
            Live: '${selectedGameLobbyUIDesigns['LIVE CASINO'].alt}',
            Slot: '${selectedGameLobbyUIDesigns['SLOT GAMES'].alt}',
            ESports: '${selectedGameLobbyUIDesigns['ESPORTS'].alt}',
            Fishing: '${selectedGameLobbyUIDesigns['FISHING GAME'].alt}',
            Lottery: '${selectedGameLobbyUIDesigns['LOTTERY'].alt}',
            Chess: '${selectedGameLobbyUIDesigns['CHESS GAME'].alt}'
          }
        },
        customMobile: {
          style: 'template-h5app65',
          gpLogoStyle: 'v-dark',
          components: {
            defaultTemplate: 'template',
            GameList: 'template-h',
            Navigation: 'template-h2',
            FunctionBar: 'template-h2',
            HomePage: 'template-h5app65',
            NavBar: 'template-h5app65',
            Animal: 'template-col1',
            Chess: 'template-col1',
            Egame: 'template-col2-full',
            Esports: 'template-col1',
            Finance: 'template-col1',
            Fishing: 'template-col1',
            Live: 'template-col2',
            Lottery: 'template-col1',
            MiniGames: 'template-col1',
            Sports: 'template-col1',
            FloatingGames: 'mini-games'
          },
          mobilePromoNavType: 'swiper'
        },
        extraFiles: {
          'vi-VN': 'ec67'
        }
      },
      locales: [ 'vi-VN' ],
      defaultLocale: '${defaultLanguage.abbr}',
      defaultCurrency: '${defaultCurrency.abbr}',
      defaultTelCountry: '${defaultTelCountry.code}',
      portal: portal,
      order: {
        LAYOUT: [ 'Header', 'Navigation', 'Menu', 'News', 'Main', 'Footer' ]
      },
      color: {
        'bg-color': '#27293c',
        'color-highlight': '#2d4454',
        'color-highlight2': '#2d4454',
        'color-secondary': '#3CAAE1',
        'color-footer': '#3c3b3b',
        'color-sidemenu': 'rgba(255, 255, 255, 0.9)',
        hyperlink: '#3CAAE1'
      },
      colorMobile: {
        'm-bg-color': '#ffffff',
        'm-color-highlight': '#2d4454',
        'm-color-highlight2': '#2d4454',
        'm-color-require': '#dd9c4b',
        'm-color-secondary': '#8FC31F',
        'm-color-coin': '#ffc446',
        'm-color-border': '#e7e7e7',
        'm-light-bg': '#f0eff5',
        'm-bottom-nav-dot': '#E94951',
        'm-W-bg-color': '#ffffff',
        'm-B-bg-color': '#272b3a',
        'm-B-bg-gray-color': '#394462',
        'm-B-money-icon-lingt-bg': '#FCC83C',
        'm-B-money-icon-dark-bg': '#F8971C',
        'm-B-sub-menu': '#4868E7',
        'm-B-home-quick-nav-light-bg': '#4868E7',
        'm-B-home-quick-nav-dark-bg': '#2347D6',
        'm-B-bottom-nav': '#131313',
        'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)',
        'm-B-bottom-nav-highlight-text': '#DCAB4C'
      },
      uploadsize: {
        screenshot: 1000000
      }
    }`;

    // Add the file to the zip
    zip.file("Agent.js", agentData);
    zip.file("Ims.js", imsData);
    zip.file("Ec.js", ecData);

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "Config.zip");
    });
  };

  return (
    <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit timeout={{enter: 500, exit: 500}}>
    <div className="form-main-container" >
      <div className="form-left-panel" >
        <div style={!MOBILE_WIDTH ? {} : {width: '90%'}} >
          {/* Mobile Template */}
          <div className="template-container">
            <div className="template-title">MOBILE TEMPLATE</div>
            <div className="template-details">
              <div className="image-cropper">
                {selectedMobileUIDesign && 
                <img 
                  style={{width: '106%', objectPosition: '-2px -13px', marginTop: 0}} 
                  src={`https://101.it.com/images/${selectedMobileUIDesign.src}.webp`}
                  alt={selectedMobileUIDesign.alt} 
                  loading='lazy'
                />}
              </div>
              <div className="ui-details">
                <div>
                  <div className="template-category">Mobile</div>
                  {selectedMobileUIDesign && <div className="template-name">{selectedMobileUIDesign.alt}</div>}
                </div>
                <div onClick={() => handleRemoveOrBrowseUI('mobile')} className="template-action">
                  {selectedMobileUIDesign ? 'Remove selection' : 'Browse options'}
                </div>
              </div>
            </div>
          </div>
          {/* Desktop Template */}
          <div className="template-container">
            <div className="template-title">DESKTOP TEMPLATE</div>
            <div className="template-details">
              <div className="image-cropper">
                {selectedDesktopUIDesign && 
                <img 
                  src={`https://101.it.com/images/${selectedDesktopUIDesign.src}.webp`}
                  alt={selectedDesktopUIDesign.alt} l
                  oading='lazy'/>}
              </div>
              <div className="ui-details">
                <div>
                  <div className="template-category">Desktop</div>
                  {selectedDesktopUIDesign && <div className="template-name">{selectedDesktopUIDesign.alt}</div>}
                </div>
                <div onClick={() => handleRemoveOrBrowseUI('desktop')} className="template-action">
                  {selectedDesktopUIDesign ? 'Remove selection' : 'Browse options'}
                </div>
              </div>
            </div>
          </div>
          {/* Lobby Template */}
          <div style={{marginBottom: 0, borderBottom: 0}} className="template-container">
            <div className="template-title">LOBBY TEMPLATE</div>
            {Object.keys(selectedGameLobbyUIDesigns).map((key, index) => (
              <div key={index} style={{ marginBottom: 8 }} className="template-details">
                <div className="image-cropper">
                  <img 
                    src={`https://101.it.com/images/${selectedGameLobbyUIDesigns[key].src}.webp`}
                    alt={selectedGameLobbyUIDesigns[key].alt} 
                    loading='lazy'
                  />
                </div>
                <div className="ui-details">
                  <div>
                    <div className="template-category">
                      {capitalizeFirstLetter(selectedGameLobbyUIDesigns[key].category)}
                    </div>
                    <div className="template-name">{selectedGameLobbyUIDesigns[key].alt}</div>
                  </div>
                  <div onClick={() => handleRemoveOrBrowseUI('lobby')} className="template-action">Browse options</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-right-panel">
        <div style={{width: '100%'}}>
          <div className="info-title">
            YOUR SITE SETTING
          </div>
          <div className="instruction-container">
            <div className="instruction-title">
              Please complete all fields
            </div>
            <div className="instruction-details">
              Download your configuration file and provide it to the business specialist When you fill up!
            </div>
          </div>
          <div className="info-container">
            <div className="instruction-title">
              Basic Information
            </div>
            <TextField
              id="input-box"
              placeholder="Website Name"
              value={websiteName}
              onChange={handleWebsiteNameChange}
            />
            <TextField 
              id="input-box"
              helperText="Only lowercase and numbers are permitted" 
              placeholder="Website ID"
              value={websiteId}
              onChange={getWebsiteId}
            />
          </div>
          <div className="settings-contatiner">
            <div className="instruction-title">
              Language and Currency
            </div>
            <Autocomplete
              className="custom-dropdown"
              disableCloseOnSelect
              multiple
              size="small"
              options={languages}
              value={selectedLanguages}
              onChange={handleSelectedLanguagesChange}
              renderOption={(props, option) => (
                <li {...props} style={{fontFamily: 'Proxima Nova', fontSize: 14, fontWeight: 400, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <div>{option.name}</div>
                  <div style={{fontWeight: 700}}>{option.abbr}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={selectedLanguages.length === 0 ? 'Select Languages' : ''}
                />
              )}
              renderTags={(selectedOptions, getTagProps) =>
                selectedOptions.map((option, index) => (
                  <Chip
                    key={option.abbr}
                    sx={{
                      '& .MuiChip-deleteIcon': {
                        display: 'none',
                      },
                      '& .MuiChip-label': {
                        paddingX: '0px',
                      },
                    }}
                    label={option.abbr}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
            <Autocomplete
              className="custom-dropdown"
              size="small"
              options={languages}
              getOptionLabel={(option) => option.name} // Ensure the input shows only the name
              value={defaultLanguage}
              onChange={handleDefaultLanguageChange}
              renderOption={(props, option) => (
                <li {...props} style={{fontFamily: 'Proxima Nova', fontSize: 14, fontWeight: 400, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <div>{option.name}</div>
                  <div style={{fontWeight: 700}}>{option.abbr}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Default Language" />
              )}
            />
            <Autocomplete
              className="custom-dropdown"
              size="small"
              options={currencies}
              getOptionLabel={(option) => option.abbr} // Ensure the input shows only the name
              value={defaultCurrency}
              onChange={handleDefaultCurrencyChange}
              renderOption={(props, option) => (
                <li {...props} style={{fontFamily: 'Proxima Nova', fontSize: 14, fontWeight: 400, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <div>{option.name}</div>
                  <div style={{fontWeight: 700}}>{option.abbr}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Currency" />
              )}
            />
            <Autocomplete
              className="custom-dropdown"
              size="small"
              options={countryTelCode}
              getOptionLabel={(option) => option.code} // Ensure the input shows only the name
              value={defaultTelCountry}
              onChange={handleDefaultTelCountryChange}
              renderOption={(props, option) => (
                <li {...props} style={{fontFamily: 'Proxima Nova', fontSize: 14, fontWeight: 400, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <div>{option.name}</div>
                  <div style={{fontWeight: 700}}>{option.code}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Country Tel. Code" />
              )}
            />
          </div>
        </div>
        <div className='download-container' >
          <Button 
            variant="contained"
            id="template-button"
            sx={{ background: '#0D65D9', color: '#FFFFFF'}}
            onClick={downloadZipFile}
            disabled={!isDataComplete()}
          >
            Download
          </Button>
          <Button 
            id="template-button"
            onClick={showTemplateForm}
          >
            Hide
          </Button>
        </div>
      </div>
    </div>
    </Slide>
  );
};

export default TemplateForm;