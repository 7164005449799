import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const HomeRow3 = () => {
  const navigate = useNavigate();

  const handleNavigation = (value) => {
    navigate(value);
  };
  
  return (
    <div className='home-row3-container'>
      <div className='column1'>
        <div className='row-title' style={{color: '#F2F2F2', height: 'clamp(38px, 8vw, 88px)', fontSize: 'clamp(40px, 8vw, 96px)'}}>
          ABOUT US
        </div>
        <div className='description' style={{color: '#FFFFFF', width: '71%', fontSize: 'clamp(16px, 2.3vw, 24px)'}}>
          Lorem ipsum dolor sit amet consectetur. Netus risus nibh pharetra porttitor facilisis. Ut massa nisi consequat in habitant in neque vitae. Viverra dignissim aliquet quisque nunc sed sed enim.
        </div>
        <Button id='action-button' onClick={() => handleNavigation('/about-us')}  sx={{maxWidth: '184px'}}>
          View more
        </Button>
      </div>
      <div className='column2'>
        <div className='row-title' style={{color: '#F2F2F2', height: 'clamp(38px, 8vw, 88px)', fontSize: 'clamp(40px, 8vw, 96px)'}}>
          WHY CHOOSE US
        </div>
        <div className='description' style={{color: '#FFFFFF', width: '71%', fontSize: 'clamp(16px, 2.3vw, 24px)'}}>
          We are passionate about developing better gaming solutions that enables the ultimate entertainment experience.
          <br/>
          Build your website with us!
        </div>
        <Button id='action-button' onClick={() => handleNavigation('/get-started')}  sx={{maxWidth: '184px'}}>
          Get started
        </Button>
      </div>
    </div>
  );
};

export default HomeRow3;