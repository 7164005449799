import React from 'react';
import HomeRow1 from './HomeRow1';
import HomeRow2 from './HomeRow2';
import HomeRow3 from './HomeRow3';

const Home = () => {
  return (
    <div>
      <HomeRow1/>
      <HomeRow2/>
      <HomeRow3/>
    </div>
  );
}

export default Home;