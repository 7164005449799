import React, { useState, useEffect, useRef } from 'react';
import './AboutPage.css';
import { Slide } from '@mui/material';

const figuresData = [
  {
    value: "58.3",
    duration: 2,
    isFloat: true,
    subtitle: "Our systems handled 58.3 billion gaming transactions in 2019."
  },
  {
    value: "20+",
    duration: 2,
    isFloat: false,
    subtitle: "We have been pioneering the online gaming industry for over 20 years!"
  },
  {
    value: "200+",
    duration: 2,
    isFloat: false,
    subtitle: "We have more than 200 active customers, covering the largest gaming operators in the world."
  },
  {
    value: "200",
    duration: 2,
    isFloat: false,
    subtitle: "Innovative and immersive UI giving you the best user experience."
  }
];

const AnimatedNumber = ({ value, duration, isFloat }) => {
  const [count, setCount] = useState(0);
  const end = parseFloat(value);

  useEffect(() => {
    if (count === end) return; // Early return if already at end

    const incrementTime = (duration / end) * 400; // Interval time
    const incrementStep = end / (duration * 400 / incrementTime); // Adjust increment step for float values

    const timer = setInterval(() => {
      setCount(prevCount => {
        const newCount = prevCount + incrementStep;
        if (newCount >= end) {
          clearInterval(timer);
          return end; // Ensure it doesn't exceed the end value
        }
        return newCount;
      });
    }, incrementTime);

    return () => clearInterval(timer);
  }, [end, duration, count]);

  return (
    <div className='figures-title'>
      {isFloat ? count.toFixed(1) + "”" : Math.floor(count)}{value.includes("+") ? "+" : ""}
    </div>
  );
};

const AboutRow3 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [slideIn, setSlideIn] = useState([false, false, false, false]); // State to control each slide
  const rowRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Set up sequential visibility for each slide
          slideInSequence();
        }
      },
      { threshold: 0.1 } // Adjust as needed for how much of the element needs to be visible
    );

    if (rowRef.current) {
      observer.observe(rowRef.current);
    }

    return () => {
      if (rowRef.current) {
        observer.unobserve(rowRef.current);
      }
    };
  }, []);

  // Function to handle sequential slide-in
  const slideInSequence = () => {
    const delays = [0, 400, 800, 1200]; // Adjust delays as needed
    delays.forEach((delay, index) => {
      setTimeout(() => {
        setSlideIn(prev => {
          const newSlideIn = [...prev];
          newSlideIn[index] = true;
          return newSlideIn;
        });
      }, delay);
    });
  };

  return (
    <div className='row3-container'>
      <div className='row1'>
        <div className='label'>
          <div className='row-title' style={{ color: '#0D65D9', fontSize:'clamp(40px, 5.5vw, 96px)' }}>
            FACTS & FIGURES
          </div>
          <div className='description' style={{fontSize:'clamp(16px, 1.5vw, 24px)', maxWidth: 1300}}>
            Lorem ipsum dolor sit amet consectetur. Nibh nulla vulputate cursus gravida turpis praesent. Sed ac sit aliquet neque netus. Id dignissim eget malesuada volutpat. Vitae varius mauris id egestas etiam. Euismod magna imperdiet convallis viverra molestie. Mauris mauris scelerisque tristique tortor nisl commodo adipiscing.
          </div>
        </div>
        <div className="figures-container" ref={rowRef}>
          {figuresData.map((figure, index) => (
            <Slide
              key={index}
              container={rowRef.current}
              direction="up"
              in={isVisible && slideIn[index]}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <div className="figures-column">
                <AnimatedNumber value={figure.value} duration={figure.duration} isFloat={figure.isFloat} />
                <div className="figures-subtitle">{figure.subtitle}</div>
              </div>
            </Slide>
          ))}
        </div>
      </div>
      <div className='row2'>
        <div className='label'/>
        <div style={{ zIndex: 1, padding: '40px 16px' }}>
          <div className='row-title' style={{ color: '#FFFFFF', fontSize:'clamp(40px, 8vw, 96px)' }}>
            WE ARE GLOBAL
          </div>
          <div className='description' style={{ color: '#FFFFFF', fontSize:'clamp(16px, 2vw, 24px)', maxWidth: 1300 }}>
            Lorem ipsum dolor sit amet consectetur. Nibh nulla vulputate cursus gravida turpis praesent. Sed ac sit aliquet neque netus. Id dignissim eget malesuada volutpat. Vitae varius mauris id egestas etiam. Euismod magna imperdiet convallis viverra molestie. Mauris mauris scelerisque tristique tortor nisl commodo adipiscing. Condimentum quisque consectetur morbi morbi sed. Et tincidunt parturient gravida dictum maecenas platea.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRow3;
